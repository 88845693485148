<template>
  <el-container class="device-list">
    <el-aside ref="aside" :width="showAside ? '232px' : '16px'" style="margin-right: 16px;">
      <el-button :icon="showAside ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        class="treeToggle" @click="handleCollapse"></el-button>
      <transition name="el-fade-in-linear">
        <vm-tree v-show="showAside" class="treeSide" type="useUnit/false" @node-click="onNodeClick"
          @area-click="onAreaClick" @node-clear="onNodeClear">
        </vm-tree>
      </transition>
    </el-aside>
    <el-main style="padding:0">
      <div class="tools-bar">
        <el-input ref="searchInput" v-model.trim="filter" class="search-bar" clearable
          :placeholder="$t('elevator.dtuCode') + '/' + $t('elevator.useUnit') + '/' + $t('elevator.no') + '/' + $t('elevator.registerCode') + '/' + $t('elevator.name')"
          @keyup.enter.native="handleSearch">
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
        <el-select v-model="integrationKey" class="search-bar" style="margin-right:10px; width: 150px;"
          :placeholder="$t('elevator.accessPlatform')" clearable @change="handleSearch">
          <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
            :value="item.integrationKey"></el-option>
        </el-select>
        <el-button-group class="expand">
          <el-button style="border-radius: 8px 8px 8px 8px;font-size: 14px;" type="primary" @click="expandAll(true)">{{
            $t("device.expandAll") }}</el-button>
          <el-button style="border-radius: 8px 8px 8px 8px;font-size: 14px;" type="primary" @click="expandAll(false)">{{
            $t("device.collapseAll") }}</el-button>
        </el-button-group>
        <el-button :loading="exportLoading" style="margin-left: 30px;border-radius: 8px 8px 8px 8px;font-size: 14px;"
          @click="exportExcel">
          {{ $t("device.exportExcel") }}
        </el-button>
      </div>
      <div class="count">
        <span class="count-title">{{ $t("device.dtuNum") }}</span>
        <el-radio-group v-model="dtuStatus" @change="dtuStatusClick">
          <el-radio-button label="0">
            {{ $t("device.offline") }} {{ statistics.dtuCount - statistics.dtuOnlineCount }}
          </el-radio-button>
          <el-radio-button label="1">{{ $t("device.online") }} {{ statistics.dtuOnlineCount }}</el-radio-button>
          <el-radio-button label="-1">{{ $t("device.all") }} {{ statistics.dtuCount }}</el-radio-button>
        </el-radio-group>

        <span class="count-title " style="margin-left: 10px">{{ $t("company.elevCount") }}</span>
        <el-radio-group disabled>
          <el-radio-button label="0">
            {{ $t("device.offline") }} {{ statistics.nodeCount - statistics.nodeOnlineCount }}
          </el-radio-button>
          <el-radio-button label="1">{{ $t("device.online") }} {{ statistics.nodeOnlineCount }}</el-radio-button>
          <el-radio-button label="-1">{{ $t("device.all") }} {{ statistics.nodeCount }}</el-radio-button>
          <el-radio-button label="-1">
            {{ $t("device.onlineRate") }}
            {{ Number(statistics.nodeOnlineCount / statistics.nodeCount * 100).toFixed(2).toString() + "%" }}
          </el-radio-button>
        </el-radio-group>
        <el-button type="success" icon="el-icon-refresh" style="float: right;border-radius: 8px;font-size: 14px"
          @click="refresh">
          {{ $t("device.refresh") }}
        </el-button>
      </div>
      <div class="count" v-if="false">
        <span class="count-title">{{ $t("device.offlineDuration") }}</span>
        <el-radio-group v-model="outlineStatus" @change="outlineStatusClick">
          <el-radio-button label="1">{{ $t("device.within2Hours") }}</el-radio-button>
          <el-radio-button label="2">{{ $t("device.more2hoursLess1Day") }}</el-radio-button>
          <el-radio-button label="3">{{ $t("device.moreThan1Days") }}</el-radio-button>
          <el-radio-button label="4">{{ $t("device.custom") }}</el-radio-button>
        </el-radio-group>
        <el-row v-if="outlineStatus === '4'" style="margin: 10px 0 0 0;">
          <el-col :span="2">
            <el-input v-model="endTime" clearable></el-input>
          </el-col>
          <el-col :span="5" style="text-align:center;">
            <span class="count-title" style="line-height: 24px; margin: 0 5px;">{{ $t("device.offlineDuration") }}
            </span>
          </el-col>
          <el-col :span="2">
            <el-input v-model="startTime" clearable></el-input>
          </el-col>
          <el-col :span="9">
            <el-radio-group v-model="unit" style="margin: 8px 0 0 15px;">
              <el-radio :label="1">{{ $t("device.minute") }}</el-radio>
              <el-radio :label="2">{{ $t("device.hour") }}</el-radio>
              <el-radio :label="3">{{ $t("device.day") }}</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" style="float: right;border-radius: 8px;font-size: 14px" @click="customClick">
              {{ $t("common.search") }}
            </el-button>
          </el-col>
        </el-row>
      </div>
      <el-table ref="dataTable" v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <table class="childTable">
              <tr>
                <th>{{ $t("elevator.nodeCode") }}</th>
                <th>{{ $t("elevator.no") }}</th>
                <th>{{ $t("elevator.registerCode") }}</th>
                <th>{{ $t("elevator.name") }}</th>
                <th style="text-align: center;width: 100px">{{ $t("device.status") }}</th>
                <th style="text-align: center;width: 200px">{{ $t("common.operation") }}</th>
              </tr>
              <tr v-for="(node, index) in scope.row.nodeList" :key="index">
                <td>{{ node.nodeCode }}</td>
                <td>
                  <router-link :to="'/elevator/' + node.elevatorId" target="_blank">{{ node.elevatorNo }}</router-link>
                </td>
                <td>{{ node.registerNo }}</td>
                <td>{{ node.elevatorName }}</td>
                <td style="text-align: center">
                  <el-tag type="success" v-if="node.online">{{ $t("device.online") }}</el-tag>
                  <el-tag v-else type="info">{{ $t("device.offline") }}</el-tag>
                </td>
                <td style="text-align: center">
                  <el-button type="text" style="color: #00C291;"
                    @click="$refs.logList.open(node.dtuCode + ':' + node.nodeCode)">
                    {{ $t("device.record") }}
                  </el-button>
                  <el-button type="text" :disabled="!node.online" :title="node.online ? '' : $t('device.offline')"
                    @click="gotoMonitor(node)">
                    {{ $t("device.monitor") }}
                  </el-button>
                  <el-button v-if="node.videoUrl" type="text" @click="link(node.videoUrl)">
                    {{ $t("device.video") }}
                  </el-button>
                  <el-button type="text" style="color: #00C291;"
                    @click="$refs.intercomSet.open(node.dtuCode, node.nodeCode)">
                    {{ $t("device.intercom") }}
                  </el-button>
                </td>
              </tr>
            </table>
          </template>
        </el-table-column>
        <table-column prop="dtuCode" :label="$t('elevator.dtuCode')" :width="150"></table-column>
        <table-column prop="useUnitName" :label="$t('elevator.useUnit')" :width="200"></table-column>
        <table-column prop="useUnitAddress" :label="$t('elevator.address')"></table-column>
        <table-column :label="$t('device.onlineNode')" align="center" :width="100">
          <template #default="scope">
            {{ scope.row.filteredOnlineNodeCount }}/{{ scope.row.filteredNodeCount }}
          </template>
        </table-column>
        <table-column :label="$t('device.status')" align="center" :width="120">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.online">{{ $t("device.online") }}</el-tag>
            <el-tag v-else type="info">{{ $t("device.offline") }}</el-tag>
          </template>
        </table-column>
        <table-column :label="$t('common.operate')" :width="200" align="center" :tooltip="false">
          <template #default="scope">
            <el-button type="text" @click="$refs.logList.open(scope.row.dtuCode)">
              {{ $t("device.record") }}
            </el-button>
            <el-button v-if="upgradeAuth" type="text" @click="handleUpgrade(scope.row)">
              {{ $t("device.upgrade") }}
            </el-button>
            <el-button type="text" @click="$refs.status.open(scope.row.dtuCode)">
              {{ $t("device.status") }}
            </el-button>
          </template>
        </table-column>
      </el-table>
      <div style="margin-top: 16px;overflow: hidden">
        <el-pagination class="page" :page-size="15" :current-page="currentPage" layout="prev, pager, next, jumper"
          :total="total" @current-change="changePage"></el-pagination>
      </div>
    </el-main>
    <device-log-list ref="logList"></device-log-list>
    <status ref="status"></status>
    <intercom-set ref="intercomSet"></intercom-set>
  </el-container>
</template>
<script>
import VmTree from "../../components/VmTree.vue";
import Status from "./Status.vue";
import IntercomSet from "./Intercom";
import DeviceLogList from "@/views/device/DeviceLogList";


export default {
  components: { DeviceLogList, VmTree, Status, IntercomSet },
  data() {
    return {
      showAside: false,
      exportLoading: false,
      upgradeAuth: this.$auth(802),
      loading: false,
      currentPage: 1,
      filter: "",
      total: 0,
      tableData: [],
      statistics: {
        nodeOnlineCount: "",
        dtuCount: "",
        nodeCount: "",
        dtuOnlineCount: "",
      },
      integrationKey: "",
      integrationList: [],
      outlineTimesStart: 0,
      outlineTimesEnd: 0,
      dtuStatus: -1,
      outlineStatus: -1,
      startTime: null,
      endTime: null,
      unit: 2,
      useUnitId: 0,
      districtId: 0,
    };
  },
  mounted() {
    this.getList(1);
    this.getIntegrationList();
  },
  methods: {
    getList(pageIndex) {
      this.currentPage = pageIndex;
      this.loading = true;
      let params;
      if (this.districtId > 0) {
        params = {
          pageIndex,
          integrationKey: this.integrationKey,
          dtuStatus: this.dtuStatus,
          districtId: this.districtId,
          filter: this.filter,
        };
      } else {
        params = {
          pageIndex,
          integrationKey: this.integrationKey,
          dtuStatus: this.dtuStatus,
          useUnitId: this.useUnitId,
          filter: this.filter,
        };
      }
      if (this.outlineTimesStart > 0 || this.outlineTimesEnd > 0) {
        params.outlineTimesStart = this.outlineTimesStart;
        params.outlineTimesEnd = this.outlineTimesEnd;
      }
      this.$api.getList("devices", params).then(res => {
        this.loading = false;
        let data = res.data;
        this.tableData = data.pageInfo.list;
        this.statistics = data.statistics;
        this.total = data.pageInfo.total;
      }).catch(() => {
        this.loading = false;
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
    exportExcel() {
      this.exportLoading = true;
      let params;
      if (this.districtId > 0) {
        params = {
          integrationKey: this.integrationKey,
          dtuStatus: this.dtuStatus,
          districtId: this.districtId,
          isCn: this.$i18n.isCn ? 1 : 0,
          filter: this.filter,
        };
      } else {
        params = {
          integrationKey: this.integrationKey,
          dtuStatus: this.dtuStatus,
          useUnitId: this.useUnitId,
          isCn: this.$i18n.isCn ? 1 : 0,
          filter: this.filter,
        };
      }
      if (this.outlineTimesStart > 0 || this.outlineTimesEnd > 0) {
        params.outlineTimesStart = this.outlineTimesStart;
        params.outlineTimesEnd = this.outlineTimesEnd;
      }
      this.$api
        .exportFile("devices/export", params, 240)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "";
          if (this.$i18n.isCn) {
            fileName = "电梯信息.xlsx";
          } else {
            fileName = "Elevator Info.xlsx";
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleSearch() {
      this.getList(1);
    },
    changePage(pageIndex) {
      this.getList(pageIndex);
    },
    expandAll(flag) {
      this.tableData.forEach(row => {
        this.$refs.dataTable.toggleRowExpansion(row, flag);
      });
    },
    dtuStatusClick(status) {
      this.dtuStatus = status;
      this.getList(1);
    },
    outlineStatusClick(status) {
      this.outlineStatus = status;
      if (this.outlineStatus === "4") {
        return;
      }
      if (this.outlineStatus === "1") {
        this.outlineTimesStart = 120;
        this.outlineTimesEnd = 0;
      } else if (this.outlineStatus === "2") {
        this.outlineTimesStart = 1440;
        this.outlineTimesEnd = 120;
      } else if (this.outlineStatus === "3") {
        this.outlineTimesStart = 60 * 24 * 7;
        this.outlineTimesEnd = 1440;
      }
      this.getList(1);
    },
    customClick() {
      if (this.startTime || this.endTime) {
        if (isNaN(this.startTime) || isNaN(this.endTime) || this.startTime < 0 || this.endTime < 0) {
          this.$message.error(this.$t("device.illegalType"));
          return;
        }
        if (this.endTime - this.startTime >= 0) {
          this.$message.error(this.$t("device.cannotBeGreater"));
          return;
        }
      } else {
        this.$message.error(this.$t("device.pleaseEnter"));
        return;
      }
      if (this.unit === 1) {
        this.outlineTimesStart = this.startTime;
        this.outlineTimesEnd = this.endTime;
      } else if (this.unit === 2) {
        this.outlineTimesStart = this.startTime * 60;
        this.outlineTimesEnd = this.endTime * 60;
      } else if (this.unit === 3) {
        this.outlineTimesStart = this.startTime * 60 * 24;
        this.outlineTimesEnd = this.endTime * 60 * 24;
      }
      this.getList(1);
    },
    onNodeClick(data) {
      this.useUnitId = data.id;
      this.districtId = 0;
      this.getList(1);
    },
    onAreaClick(data) {
      this.districtId = data.id;
      this.useUnitId = 0;
      this.getList(1);
    },
    onNodeClear() {
      this.useUnitId = 0;
      this.districtId = 0;
      this.getList(1);
    },
    gotoMonitor(node) {
      console.log(this.$i18n.isCn);
      if(this.$i18n.isCn) {
        window.open("/#/singleMonitor/" + node.elevatorId);
      } else {
        window.open("/#/singleMonitorOld/" + node.elevatorId);
      }

    },
    handleUpgrade(row) {
      this.$confirm(this.$t("device.upgradeConfirm"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.getData("devices/upgrade/" + row.dtuCode).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch((error) => {
          this.$message.error(this.$t("device.upgrade") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
        });
      });
    },
    handleFlowcard(row) {
      if (!row.iccid || row.iccid === "" || row.iccid === "0") {
        this.$message.error(this.$t("device.iccidIsEmpty"));
      } else {
        window.open("#/simCard/" + row.iccid);
      }
    },
    refresh() {
      this.outlineTimesStart = 0;
      this.outlineTimesEnd = 0;
      this.outlineStatus = -1;
      this.getList(1);
    },
    link(videoUrl) {
      window.open(videoUrl);
    },
    sendA0(dtuCode, nodeCode) {
      console.log(dtuCode, nodeCode);
      let params = {
        "dtuCode": dtuCode,
        "nodeCode": nodeCode,
      };
      this.$api.getData("deviceGateway/sendA0", params).then(res => {
        this.$message.success(this.$t("common.tip.operationSuccess"));
      }).catch((err) => {
        this.$message.error(err);
      });
    },
    handleCollapse() {
      this.showAside = !this.showAside;
      if (this.showAside) {
        this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
      } else {
        this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/variables.scss";

.device-list {
  ::v-deep .el-table__expanded-cell {
    background-color: #F1F5F8;
    padding: 15px 25px;

    &:hover {
      background-color: #F1F5F8 !important;
    }
  }

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: white;
  }

  ::v-deep .el-radio-button__orig-radio:disabled+.el-radio-button__inner {
    color: #888
  }

  .tools-bar {
    overflow: hidden;

    .search-bar {
      float: right;
      right: 0;
      width: 430px;

      i {
        cursor: pointer;
      }
    }
  }

  .count {
    margin: 10px 0;
    background-color: #f4f7fa;
    padding: 10px 20px;
    color: #333;
    border: 1px solid #E3EBF2;

    .count-title {
      font-size: 14px;
      color: #777;
      margin-right: 5px;
    }
  }

  .childTable {
    width: 100%;
    border-collapse: collapse;

    td {
      border: 1px solid #D8DCE5;
      padding: 5px 10px;
      background-color: #FAFAFA;
    }

    th {
      border: 1px solid #D8DCE5;
      padding: 8px 10px;
      background-color: #EEF2F7;
    }
  }

  .page {
    float: right;
  }
}

.vm-main .vm-main-page .vm-content .vm-page {
  padding: 17px 22px 17px 0;
}
</style>
